<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : 'px-5'">

    <!-- <v-breadcrumbs :items="items"></v-breadcrumbs> -->
    <Snackbar ref="snackbar" />
    
    <!-- ==== FILTER SUMMARY ==== -->
    <!-- <v-row justify="start">
      <v-col cols="12" md="6">
        <v-select
          outlined
          dense
          v-model="filter.store_id"
          color="#F05326"
          attach
          item-text="store_name"
          item-value="store_id"
          :hide-details="true"
          label="Pilih Toko">
        </v-select>
      </v-col>
      <v-col  cols="12" md="6">
        <date-picker
          v-model="value2"
          type="datetime"
          format="DD MMMM YYYY HH:mm:ss"
          range
          :clearable="false"
          :disabled-date="disabledAfterThisDay"
          :shortcuts="shortcuts"
          :show-time-panel="showTimeRangePanel"
          @close="showTimeRangePanel = false"
          placeholder="Pilih Tanggal">
          <template v-slot:header>
            <div class="title-date">Pilih Tanggal</div>
          </template>
          <template v-slot:footer>
            <button class="mx-btn mx-btn-text" @click="showTimeRangePanel = !showTimeRangePanel">
              {{ showTimeRangePanel ? 'select date' : 'select time' }}
            </button>
          </template>
        </date-picker>
      </v-col>
    </v-row> -->

    <p class="body-1 px-5 font-weight-bold text-first pt-0">DASHBOARD</p>
    <v-divider class="pb-5"></v-divider>

    <!-- ==== DATA SUMMARY ==== -->
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" 
          sm="6" 
          lg="6" 
          md="6" 
          xs="12" 
          v-for="(item, index) in process.run ? 4 : summary"
          :key="index">
            <v-skeleton-loader
              class="ma-auto"
              height="150"
              :loading="process.run"
              transition="scale-transition"
              :tile="false"
              type="image">
              <v-card outlined
                class="box-shadow border-radius pa-0 pb-1">
                <v-card-title class="d-flex align-center justify-center flex-column">
                  <v-icon size="25" class="main-color rounded-lg" style="background: #F0532640; padding: 3px;">{{ item.icon }}</v-icon>
                  <div class="subtitle-1 font-weight-bold mt-3 grey--text text--darken-1">
                    {{ item.title }}
                  </div>
                </v-card-title>
                <v-card-text class="text-center text-h5 font-weight-bold black--text">
                  {{ item.total }}
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-card outlined
          class="box-shadow border-radius pa-0 pb-1"
          style="height: 100%">
          <v-card-title class="d-flex align-center justify-center flex-column">
            <v-icon size="125" class="main-color rounded-lg mb-7" style="background: #F0532640; padding: 3px;">mdi-database</v-icon>
            <div class="subtitle-1 font-weight-bold mt-3 black--text">
              Total User Subscribe Hari Ini
            </div>
          </v-card-title>
          <v-card-text class="text-center">
            <div class="text-h6 black--text pb-3" style="font-weight:700">
              {{ total_user_subcriber_now | num_format }} User
            </div>
              {{ new Date | dateFull }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- ==== DATA CHART TRAFFIC USER DAN INCOME ==== -->
    <v-row>
      <!-- TRAFFIC USER -->
      <v-col cols="12" md="6">
        <v-skeleton-loader
          class="ma-auto"
          :loading="process.traffic"
          transition="scale-transition"
          :tile="true"
          type="card">
          <v-card
            outlined
            class="box-shadow border-radius py-5">
            <v-card-title class="body-1 px-5 text-first font-weight-bold pt-0">
              <v-row>
                <v-col class="mt-2">
                  Traffic User By Topic
                </v-col>
                <v-col>
                  <v-select
                    :items="topics"
                    v-model="filter.traffic.topic"
                    item-text="topic_name"
                    item-value="id"
                    color="#F05326"
                    outlined
                    solo
                    filled
                    dense
                    flat
                    @change="getTrafficUsers()"
                    label="Filter Topic">
                  </v-select>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-card-title>
            <div class="px-3">
              <div v-if="Object.keys(traffic_user_area).length > 0">
                <apexchart 
                  height="300" 
                  :options="traffic_user_area.chartOptions" 
                  :series="traffic_user_area.series">
                </apexchart>
              </div>
              <div 
                class="title text-second d-flex align-center justify-center" 
                v-else
                style="height: 200px;">
                Data Belum Tersedia
              </div>
            </div>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <!-- Grafik User Subcribe -->
      <v-col cols="12" md="6">
        <v-skeleton-loader
          class="ma-auto"
          :loading="process.subscribe"
          transition="scale-transition"
          :tile="true"
          type="card">
          <v-card
            outlined
            class="box-shadow border-radius py-5">
            <v-card-title class="body-1 px-5 text-first font-weight-bold pt-0">
              <v-row>
                <v-col class="mt-2" cols="5">
                  Grafik User By Date
                </v-col>
                <v-col>
                  <VueCtkDateTimePicker
                    color="#F05326"
                    v-model="filter.filterdate"
                    label="Pilih Tanggal"
                    :range="true"
                    :noClearButton="true"
                    :auto-close="true"
                    format="YYYY-MM-DD"
                    formatted="LL"
                    locale="id"
                    :custom-shortcuts="custome_shortcuts"
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <!-- <v-card-subtitle class="caption px-5 text-third" v-if="filter.filterdate != null">
              <b>Tanggal: {{ filter.filterdate.start | dateFull }} - {{ filter.filterdate.end | dateFull }}</b>
            </v-card-subtitle> -->
            <div class="pa-3">
              <div v-if="Object.keys(incoming_income_bar).length > 0">
                <apexchart 
                  height="300" 
                  :options="incoming_income_bar.chartOptions" 
                  :series="incoming_income_bar.series">
                </apexchart>
              </div>
              <div 
                class="title text-second d-flex align-center justify-center" 
                v-else
                style="height: 200px;">
                Data Belum Tersedia
              </div>
            </div>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card outlined
      class="box-shadow border-radius pa-0 pb-1 mt-5">
      <v-card-title>
        Daftar User
        <v-spacer></v-spacer>
        <v-btn
          color="teal"
          class="white--text ml-3"
          depressed>
          <v-icon left>mdi-download</v-icon>
          <vue-excel-xlsx
            :data="users.list"
            :columns="columnsExcel"
            :file-name="'Data User'"
            :file-type="'xlsx'"
            :sheet-name="'Data User'">
            Export Excel
          </vue-excel-xlsx>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="mt-3">
        <v-row no-gutters>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              style="width: 300px;"
              color="#F05326"
              label="Cari berdasarkan Nama atau Email"
              prepend-inner-icon="mdi-magnify"
              clear-icon="mdi-close"
              clearable
              v-model="filter.users.searching">
            </v-text-field>
          </v-col>
          <v-col class="mr-2">
            <VueCtkDateTimePicker
              color="#F05326"
              label="Pilih Tanggal"
              v-model="filter.users.filterdate"
              :range="true"
              :noClearButton="true"
              :auto-close="true"
              format="YYYY-MM-DD"
              formatted="LL"
              locale="id"
              id="users_filter"
              :custom-shortcuts="custome_shortcuts"
            />
          </v-col>
          <v-col class="mr-2">
            <v-select
              :items="[{text: 'Subscribed', value: 1}, {text: 'Not Subscribed', value: 0}]"
              v-model="filter.users.subscription"
              item-text="text"
              item-value="value"
              color="#F05326"
              outlined
              solo
              filled
              dense
              flat
              clearable
              label="Tipe Subscribe">
            </v-select>
          </v-col>
          <v-col class="mr-2">
            <v-select
              :items="[{text: 'Email', value: 'email'}, {text: 'Google', value: 'google'}, {text: 'Facebook', value:'facebook'}, {text: 'Apple', value:'apple'}]"
              v-model="filter.users.provider"
              item-text="text"
              item-value="value"
              color="#F05326"
              outlined
              solo
              filled
              dense
              flat
              clearable
              label="Provider">
            </v-select>
          </v-col>
          <v-col>
            <v-select
              :items="[{text: 'Nama', value: 'fullname'},{text: 'Tanggal Dibuat', value:'created_at'}, {text: 'Tanggal Diubah', value:'updated_at'}, {text: 'Subscription', value:'subscription'}]"
              v-model="filter.users.sort"
              item-text="text"
              item-value="value"
              color="#F05326"
              outlined
              solo
              filled
              dense
              flat
              clearable
              label="Sort">
            </v-select>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
         <v-skeleton-loader
          class="ma-auto"
          height="150"
          :loading="process.users"
          transition="scale-transition"
          :tile="false"
          type="table">
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :no-data-text="contentEmpty"
            :items="users.list"
            :items-per-page.sync="users.limit"
            :page.sync="users.page"
            :server-items-length="users.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }">
            <template v-slot:[`item.fullname`]="{ item }">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ item.child_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:[`item.history_subscribe`]="{ item }">
              <v-btn
                elevation="0"
                color="#BBDEFB"
                class="text-capitalize"
                style="color: #2962FF"
                small
                @click="historySubscription(item)">
                Lihat History
              </v-btn>
              <!-- <v-list-item two-line v-for="(history, i) in process.run ? 2 : item.history_subscribe" :key="i">
                <v-skeleton-loader
                  class="ma-auto"
                  height="150"
                  :loading="process.run"
                  transition="scale-transition"
                  :tile="false"
                  type="text">
                  <v-list-item-content>
                    <v-list-item-title>{{ history.subscription_title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ history.license }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-skeleton-loader>
              </v-list-item> -->
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.subscription.package.subscribe_at | datetime }}
            </template>
            <template v-slot:[`item.expired_at`]="{ item }">
              {{ item.subscription.package.expired_at | datetime }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                small
                color="#F05326"
                @click="detailUsers(item)"
                class="mr-2 white--text"
                depressed>
                Detail
              </v-btn>
            </template>
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetchMember(page)"
                @changeLimit="limit = $event; fetchMember()" 
                @changePage="page = $event; fetchMember($event)" 
                :model="props"/>
            </template>
          </v-data-table>
         </v-skeleton-loader>
      </v-card-text>
    </v-card>

    <progress-history-modal
        v-model="dialog.history_subscription"
        :items="subsList && Array.isArray(subsList.list) ? subsList.list : []"
      />

  </div>
</template>

<script>
  import { get, post, put, destroy } from "../../service/Axios";
  import moment from 'moment';
  import Empty from "@/components/Empty";
  import Snackbar from '@/components/Snackbar.vue'
  import { TokenService } from "@/service/Storage.Service";
  import Pagination from '@/components/Pagination.vue'
  import ProgressHistoryModal from '@/components/progress/progress-history-modal.vue';
  export default {
    data () {
      return {
        filter: {
          filterdate: null,
          dateNow: new Date().toISOString().substr(0, 10),
          users: {},
          traffic: {}
        },
        value2: [],
        firstDay: "",
        lastDay: "",
        showTimeRangePanel: false,
        newDate: new Date(),
        shortcuts: [
          {
            text: 'Hari Ini',
            onClick(){
              const newDate = new Date();
              let firstDay = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate(),
                0
              );
              let lastDay = firstDay;
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            }
          },
          {
            text: 'Hari Kemarin',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate() - 1,
                0
              );
              let lastDay = firstDay;
              const date = [firstDay, lastDay];
              return date;
            },
          },
          {
            text: 'Minggu Ini',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(
                newDate.setDate(newDate.getDate() - newDate.getDay())
              );
              let lastDay = new Date(
                newDate.setDate(newDate.getDate() - newDate.getDay() + 6)
              );
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            }
          },
           {
            text: 'Minggu Kemarin',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(
                newDate.setDate(newDate.getDate() - newDate.getDay() - 7)
              );
              let lastDay = new Date(
                newDate.setDate(newDate.getDate() - newDate.getDay() + 6)
              );
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            }
          },
          {
            text: 'Bulan Ini',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
              let lastDay = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            },
          },
          {
            text: 'Bulan Kemarin',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(newDate.getFullYear(), newDate.getMonth() - 1, 1);
              let lastDay = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            },
          },
          {
            text: '7 Hari Kemarin',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate() - 7,
                0
              );
              let lastDay = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate(),
                0
              );
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            },
          },
          {
            text: '30 Hari Kemarin',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate() - 30,
                0
              );
              let lastDay = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate(),
                0
              );
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            },
          },
          {
            text: 'Tahun Ini',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(newDate.getFullYear(), 0);
              let lastDay = new Date(newDate.getFullYear(), 11, 31);
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            },
          },
          {
            text: 'Tahun Kemarin',
            onClick() {
              const newDate = new Date();
              let firstDay = new Date(newDate.getFullYear() - 1, 0);
              let lastDay = new Date(newDate.getFullYear() - 1, 11, 31);
              const date = [firstDay, lastDay];
              // return a Date
              return date;
            },
          }
        ],
        
        contentEmpty: "Data Belum Tersedia",
        custome_shortcuts: [
          { key: 'today', label: 'Hari ini', value: 'day' },
          { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
          { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
          { key: 'last7Days', label: '7 hari terakhir', value: 7 },
          { key: 'last30Days', label: '30 hari terakhir', value: 30 },
          { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
          { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
          // {
          //   key: 'customValue',
          //   label: '3 Bulan lalu',
          //   value: () => {
          //     return {
          //       start: moment().subtract(3, 'months'),
          //       end: moment()
          //     }
          //   },
          //   callback: ({ start, end }) => {
          //     console.log('My shortcut was clicked with values: ', start, end)
          //   }
          // },
          // { key: 'thisYear', label: 'Tahun ini', value: 'year' },
          // { key: 'lastYear', label: 'Tahun lalu', value: '-year' }
        ],
        headers: [
          {text: 'Nama Siswa', value: 'fullname', align: 'start'},
          {text: 'Nomor Handphone', value: 'phone', align: 'center'},
          {text: 'Provider', value: 'provider', align: 'center'},
          {text: 'Histori Subscribe', value: 'history_subscribe', align: 'center'},
          {text: 'Subscribe at', value: 'created_at', align: 'center'},
          {text: 'Expired at', value: 'expired_at', align: 'center'},
          {text: 'Aksi', value: 'action', align: 'center'},
        ],
        // Users data
        users: {},
        limit: 10,
        page: 1,
        selected: {
          pagination: 0
        },
        // users data
        topics: [],
        detail: {},
        subsList: {},
        summary: [
          {
            icon: "mdi-account-box-outline",
            title: "Total User Subscribe",
            total: ""
          },
          {
            icon: "mdi-account-box-outline",
            title: "Total User Belum Subscribe",
            total: ""
          },
          {
            icon: "mdi-account-box-outline",
            title: "Total User Aktif 7 Hari Terakhir",
            total: ""
          },
          {
            icon: "mdi-account-box-outline",
            title: "Total Topik Yang Rilis",
            total: ""
          },
        ],
        total_user_subcriber_now: "",
        traffic_user_area: {},
        incoming_income_bar: {},
        process: {
          run: false,
          users: false,
          traffic: false,
          subscribe: false,
        },
        dialog: {
          history_subscription: false
        },
      }
    },
    computed: {
      user () {
        return JSON.parse(TokenService.getUser())
      },
      filtered(){
        return this.value2 ? moment(this.value2[0]).format('DD MMMM YYYY HH:mm:ss') + ' - ' + moment(this.value2[1]).format('DD MMMM YYYY HH:mm:ss') : ''
      },
      filterdateStart(){
        return this.value2[0] ? moment(this.value2[0]).format('YYYY-MM-DD') : ''
      },
      filterdateEnd(){
        return this.value2[1] ? moment(this.value2[1]).format('YYYY-MM-DD') : ''
      },
      filterMinRegister() {
        return this.filter.users.filterdate ? moment(this.filter.users.filterdate.start).format('YYYY-MM-DD') : ''
      },
      filterMaxRegister() {
        return this.filter.users.filterdate ? moment(this.filter.users.filterdate.end).format('YYYY-MM-DD') : ''
      },
      columnsExcel() {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Nama",
          field: "child_name",
        },
        {
          label: "Username",
          field: "username",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Jenis Kelamin",
          field: "gender",
        },
        {
          label: "Provider",
          field: "provider",
        },
        {
          label: "Alamat",
          field: "address",
        },
        {
          label: "Umur",
          field: "age",
        },
        {
          label: "Tanggal Lahir",
          field: "birthday",
        },
        {
          label: "Tempat Lahir",
          field: "birth_place",
        },
        {
          label: "Tanggal Daftar",
          field: "created_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Akses Terakhir",
          field: "last_login",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Status Berlangganan",
          field: "subscription.is_subscribed",
          dataFormat: (value) => {
            return value == true ? '✅' : '❌'
          }
        },
        {
          label: "Nama Langganan",
          field: "subscription.package.title",
        },
        {
          label: "Tanggal Berlangganan",
          field: "subscription.package.subscribe_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "Tanggal Habis",
          field: "subscription.package.expired_at",
          dataFormat: (value) => {
            return value != null ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '-'
          }
        },
        {
          label: "License",
          field: "subsList",
          dataFormat: this.LicenseFormat,
        }
      ]
    },
    },
    watch: {
      'selected.pagination': function(val) {
        this.fetchMember(val+1)
      },
      "filter.filterdate": function(val) {
        if (val.start && val.end) {
          const filter = [val.start, val.end];
          this.value2 = filter;
        }
      },
      "filter.users.filterdate": function(val) {
        if (val.start && val.end) {
          this.fetchMember()
        }
      },
      "filter.users.searching": _.debounce(function(newVal) {
        this.fetchMember()
      }, 500),
      "filter.users.subscription": function(val) {
        this.fetchMember()
      },
      "filter.users.provider": function(val) {
        this.fetchMember()
      },
      "filter.users.sort": function(val) {
        this.fetchMember()
      },
      "filter.traffic.topic": function(val) {
        this.getTrafficUsers()
      },
      value2: function(val) {
        this.getIncomeDaily();
      },
    },
    components: {
      Empty, 
      Snackbar,
      Pagination,
      ProgressHistoryModal,
    },
    mounted () {
      this.fetchMember()
      this.getTopic()
      this.getSummarySubscribeNow()
      this.getSummarySubscribe()
      this.getSummaryUnSubscribe()
      this.getSummaryActive()
      this.getSummaryTopic()

      const newDate = new Date();
      let firstMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        1
      );
      let lastMonth = new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        0
      );

      const filter = [firstMonth, lastMonth];
      this.value2 = filter;

      this.filter.filterdate = {
        end: lastMonth,
        shortcut: "isoWeek",
        start: firstMonth,
      };
    },
    methods: {
      async getSummarySubscribeNow() {
         let _self = this;
        _self.process.run = true
        this.$store.state.process.run = true

        await get(`api/v1/member/total`,{
          params: {
            is_subscribed: 1,
            min_subscribe: moment(new Date(
                this.newDate.getFullYear(),
                this.newDate.getMonth(),
                this.newDate.getDate() - 1,
                16,
                59,
                59
              )).format('YYYY-MM-DD HH:mm:ss'),
            max_subscribe: moment(new Date(
                this.newDate.getFullYear(),
                this.newDate.getMonth(),
                this.newDate.getDate(),
                17,
                0,
                0
              )).format('YYYY-MM-DD HH:mm:ss') 
          }
        }).then(response => {
          let res = response.data
          if(res.status == 200){
            this.total_user_subcriber_now = res.data.total
          }
        })
      },
      async getSummarySubscribe() {
         let _self = this;
        _self.process.run = true
        this.$store.state.process.run = true

        await get(`api/v1/member/total`,{
          params: {
            is_subscribed: 1,
          }
        }).then(response => {
          let res = response.data
          if(res.status == 200){
            this.summary[0].total = res.data.total  + " User"
            this.process.run = false
          }
        })
      },
      async getSummaryUnSubscribe() {
         let _self = this;
        _self.process.run = true
        this.$store.state.process.run = true

        await get(`api/v1/member/total`,{
          params: {
            is_subscribed: 0,
          }
        }).then(response => {
          let res = response.data
          if(res.status == 200){
            this.summary[1].total = res.data.total + " User"
            this.process.run = false
          }
        })
      },
      async getSummaryActive() {
         let _self = this;
        _self.process.run = true
        this.$store.state.process.run = true

        await get(`api/v1/member/total`, {
          params: {
            min_last_login: moment(new Date(
                this.newDate.getFullYear(),
                this.newDate.getMonth(),
                this.newDate.getDate() - 7,
                this.newDate.getHours() - 7,
                this.newDate.getMinutes(),
                this.newDate.getSeconds()
            )).format('YYYY-MM-DD HH:mm:ss')
          }
        }).then(response => {
          let res = response.data
          if(res.status == 200){
            this.summary[2].total = res.data.total + " User"
            this.process.run = false
          }
        })
      },
      async getSummaryTopic() {
         let _self = this;
        _self.process.run = true
        this.$store.state.process.run = true

        await get(`api/v1/topic/total`).then(response => {
          let res = response.data
          if(res.status == 200){
            this.summary[3].total = res.data.total + " Topic"
            this.process.run = false
          }
        })
      },
      async getTrafficUsers() {
        await get(`admin/v1/report/log_activity/monthly`, {
          params: {
            topic_id: this.filter.traffic.topic,
            start_date: moment(new Date(this.newDate.getFullYear(), this.newDate.getMonth() - 6, 1)).format('YYYY-MM'),
            end_date: moment(new Date(this.newDate.getFullYear(), this.newDate.getMonth(), 1)).format('YYYY-MM')
          }
        })
        .then(response => {
          let res = response.data
          if(res.status == 200) {
            // TRAFFIC USERS
            this.traffic_user_area = {
              series: [
                {
                  name: 'LOG ACTIVITY: ',
                  type: 'area',
                  data: []
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000
                    }
                  },
                  toolbar: {
                    show: true
                  },
                  zoom: {
                    enabled: true
                  }
                },
                stroke: {
                  curve: 'smooth',
                  width: [3, 3]
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                      shadeIntensity: 1,
                      inverseColors: false,
                      opacityFrom: 0.45,
                      opacityTo: 0.05,
                      stops: [20, 100, 100, 100]
                    },
                },
                colors: ['#F05326', '#41af55'],
                labels: [],
                xaxis: {
                  title: {
                    text: 'Bulan',
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                        color: undefined,
                        fontSize: 10,
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 200,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                  },
                  labels: {
                    formatter: function(val, index) {
                      return moment(val).format('MMMM YYYY')
                    },
                    style: {
                      color: undefined,
                      fontSize: 10,
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = ""
                      const reverseNumber = val.toString().split("").reverse().join("")
                      const arrReverseNumber = [...Array(reverseNumber.length).keys()]
                      arrReverseNumber.map(index => {
                        if (index % 3 === 0) price += reverseNumber.substr(index, 3) + "."
                      })
                      return `${
                        price.split("", price.length - 1)
                        .reverse()
                        .join("")
                      }`
                    },
                    style: {
                      color: undefined,
                      fontSize: 10,
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  }
                }
              }
            }

            let label = [];
            let data = []

            res.data.forEach(item => {
              item.date_month = item.year +"-"+item.month
              label.push(item.date_month)
              data.push(item.total)
            })

            this.traffic_user_area.chartOptions.labels = label
            this.traffic_user_area.series[0].data = data
          }
        })
      },
      async getIncomeDaily(){
        await get(`admin/v1/member/total_daily`,{
          params:{
            start_date: this.filterdateStart,
            end_date: this.filterdateEnd,
          }
        }).then(response => {
          let res = response.data
          if (res.status === 200) {
            this.detail = res.data

            // INCOMING INCOME
            this.incoming_income_bar = {
              series: [
                {
                  name: 'USER REGISTER',
                  type: 'bar',
                  data: []
                },
                {
                  name: 'USER SUBSCRIBE',
                  type: 'bar',
                  data: []
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000
                    }
                  },
                  toolbar: {
                    show: true
                  },
                  zoom: {
                    enabled: true
                  }
                },
                stroke: {
                  curve: 'smooth',
                  width: [1, 1]
                },
                colors: ['#F05326', '#3498db'],
                labels: [],
                tooltip: {
                  enabled: true,
                  shared: true,
                  custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `
                      <div class="arrow_box" style="position: relative;display: inline-block;;background-color: #F05326;width: 200px;height: 100px;padding: 10px;font-size: 14px;font-weight: 600;">
                          <span style="color: #fff"> Tanggal : ${moment(w.globals.categoryLabels[dataPointIndex]).format('DD MMMM YYYY')} </span> <br/>
                          <span style="color: #fff"> Total User Registrasi : ${res.data[dataPointIndex].total_register} </span> <br/>
                          <span style="color: #fff"> Total User Subscribe : ${res.data[dataPointIndex].total_subscribe}</span>
                      </div>
                    `
                  }
                },
                xaxis: {
                  categories:[],
                  tickAmount: 10,
                  labels: {
                    show: true,
                    rotate: -45,
                  },
                  title: {
                    text: 'Bulan',
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                        color: undefined,
                        fontSize: 10,
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 200,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                  },
                  labels: {
                    // formatter: function(val, index) {
                    //   return moment(val).format("DD/MM/YYYY", "id")
                    // },
                    style: {
                      color: undefined,
                      fontSize: 10,
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = ""
                      const reverseNumber = val.toString().split("").reverse().join("")
                      const arrReverseNumber = [...Array(reverseNumber.length).keys()]
                      arrReverseNumber.map(index => {
                        if (index % 3 === 0) price += reverseNumber.substr(index, 3) + "."
                      })
                      return `${
                        price.split("", price.length - 1)
                        .reverse()
                        .join("")
                      }`
                    },
                    style: {
                      color: undefined,
                      fontSize: 10,
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  }
                }
              }
            }

            let label = []
            let data_register = []
            let data_subscribe = []

            res.data.forEach(item => {
              label.push(item.date)
              data_register.push(item.total_register)
              data_subscribe.push(item.total_subscribe)
            })

            this.incoming_income_bar.chartOptions.labels = label
            // this.incoming_income_bar.chartOptions.xaxis.categories = data_subscribe
            this.incoming_income_bar.series[0].data = data_register
            this.incoming_income_bar.series[1].data = data_subscribe

            this.process.run= false
          }
        }).catch( error => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            this.$store.state.ContentValidation = errorData.validation.end_date

            if (errorCode === 401) {
              this.$store.state.unauthorization = true
              this.$store.state.process.run = false
              this.process.form = false
            }
          }else{
            this.$store.state.errorNetwork = true
            this.$store.state.process.run = false
            this.process.form = false
          }
        })
      },
      async getIncomeMonthly(){
        await get(`admin/v1/member/total_monthly`,{
          params:{
            start_date: this.filterdateStart,
            end_date: this.filterdateEnd  ,
          }
        }).then(response => {
          let res = response.data
          if (res.status === 200) {
            this.detail = res.data

            // INCOMING INCOME
            this.incoming_income_bar = {
              series: [
                {
                  name: 'PENJUALAN KOTOR PER JAM',
                  type: 'bar',
                  data: []
                },
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                      speed: 1000
                    }
                  },
                  toolbar: {
                    show: true
                  },
                  zoom: {
                    enabled: true
                  }
                },
                stroke: {
                  curve: 'smooth',
                  width: [1, 1]
                },
                colors: ['#F05326'],
                labels: [],
                xaxis: {
                  labels: {
                    show: true,
                    rotate: -45,
                  },
                  title: {
                    text: 'Bulan',
                    offsetX: 2,
                    offsetY: 2,
                    style: {
                        color: undefined,
                        fontSize: 6,
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 200,
                        cssClass: 'apexcharts-xaxis-title',
                    },
                  },
                  labels: {
                    style: {
                      color: undefined,
                      fontSize: 6,
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  }
                },
                yaxis: {
                  labels: {
                    formatter: function(val, index) {
                      let price = ""
                      const reverseNumber = val.toString().split("").reverse().join("")
                      const arrReverseNumber = [...Array(reverseNumber.length).keys()]
                      arrReverseNumber.map(index => {
                        if (index % 3 === 0) price += reverseNumber.substr(index, 3) + "."
                      })
                      return `Rp ${
                        price.split("", price.length - 1)
                        .reverse()
                        .join("")
                      }`
                    },
                    style: {
                      color: undefined,
                      fontSize: 6,
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 200,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  title: {
                    offsetX: 0,
                  }
                }
              }
            }

            this.incoming_income_bar.chartOptions.labels = ["Maret", "April", "Mei", "Juni", "Juli", "Agustus"]
            this.incoming_income_bar.series[0].data = [80, 70, 80, 90, 70, 80]

            this.process.run= false
          }
        }).catch( error => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true
              this.$store.state.process.run = false
              this.process.form = false
            }
          }else{
            this.$store.state.errorNetwork = true
            this.$store.state.process.run = false
            this.process.form = false
          }
        })
      },
      changePage (event) {
        if (event === undefined) {
          this.selected.pagination = 0
        } 
      },
      async getTopic() {
        this.process.run = true

        await get(`api/v1/topic/list`,{
          params: {
            limit: 10000
          }
        }).then(response => {
          let res = response.data
          if (res.status == 200) {
            this.topics = res.data.list
            this.filter.traffic.topic = this.topics[0].id
            this.getTrafficUsers()
            this.process.run = false
          }
        })
      },
      async fetchMember(paging){
        this.process.users = true
        // this.$store.state.process.run = true

        await get(`api/v1/member/list`,{
          params:{
            limit: this.limit,
            page: paging,
            q: this.filter.users.searching,
            provider: this.filter.users.provider,
            is_subscribed: this.filter.users.subscription,
            min_register: this.filterMinRegister,
            max_register: this.filterMaxRegister
          }
        }).then(response => {
          let res = response.data
          let users = res.data
          // users.list.forEach(async (item) => {
          //   await get(`api/v1/subscription/history/list`, {
          //     params: {
          //       // limit: 10,
          //       email: item.email
          //     }
          //   }).then(response => {
          //     let resHistory = response.data.data.list

          //     if (resHistory.length > 0) {
          //       item.history_subscribe = resHistory
          //     }
          //   })
          // })
          // console.log(users);
          this.users = users
          // this.$store.state.process.run = false
          this.totalData = res.data.total
          this.process.users = false

          // this.getIncomeDaily()
          // this.getTrafficUsers()
        })
      },
      detailUsers(item) {
        this.detail = item
        this.$refs.snackbar.open("#4CAF50", `Fitur Belum Tersedia`);
      },
      async historySubscription(item) {
        this.dialog.history_subscription = true;
        await this.getSubsList({ email: item.email });
      },
      async getSubsList(data) {
        await get(`api/v1/subscription/history/list`, {
          params: {
            email: data.email,
            limit: 100
          }
        }).then(response => {
          let res = response.data
          if (res.status == 200) {
            this.subsList = res.data
          }
        })
      },
      toFilter(item) {
        this.selected = item.id
        this.filter.name = item.name
      },
    }
  }
</script>

<style scoped>
  .line {
    border-left: 6px solid #F05326 !important;
    transition: 0.3s;
  }
  .datetimepicker {
    left: -50%;
  }

</style>