<template>
  <v-dialog v-model="model" width="800">
    <v-card>
      <v-card-title>History Detail</v-card-title>
      <v-divider />
      <v-col class="px-6">
        <table class="simple-table">
          <thead>
            <tr class="grey lighten-3 text-left">
              <th>No</th>
              <th>Date</th>
              <th>Payment</th>
              <th>Subscription Type</th>
              <th>License</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.source }}</td>
              <td>{{ item.subscription_title }}</td>
              <td>{{ item.license }}</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: { prop: 'modelData', event: 'change' },
  props: { modelData: { type: [Boolean, String, Number, Object, File] }, items: Array },
  computed: {
    model: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
};
</script>

<style scoped>
.simple-table {
  width: 100%;
  border-collapse: collapse;
}
.simple-table th,
.simple-table td {
  padding: 0.5rem;
  border-bottom: solid 1px #eee;
}
</style>
